import React, { useState } from 'react';
import '../css/Parkingmap.css'; // Import CSS để thêm style

function Parkingmap() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  const sections = [
    { title: 'Khu A - Xe Ô Tô', images: ['https://i.ex-cdn.com/theleader.vn/files/f1/Upload_vn/thuphuong/2024/5/25/cho-giu-xe-chung-cu.jpg', 'https://maisonoffice.vn/wp-content/uploads/2024/08/bai-do-xe-hoi.jpg', 'https://kiemsoatvantay.vn/wp-content/uploads/2018/07/he-thong-bai-do-xe-thong-minh.1.png','https://mydinhpearls.com/wp-content/uploads/2018/07/119739703_1624072437774439_4561510441485232717_n.jpg'] },
    { title: 'Khu B - Xe Máy', images: ['https://cdn2.fptshop.com.vn/unsafe/Uploads/images/tin-tuc/182864/Originals/bai-giu-xe-may-24-24-gan-day-4.jpeg', 'https://megaparking.vn/wp-content/uploads/2019/05/kiem-soat-bai-do-xe-nha-may.jpg', 'https://mayruaxemini.vn/wp-content/uploads/2020/09/kich-thuoc-bai-do-xe-may-1.jpg'] },
    { title: 'Khu C - Xe Đạp', images: ['https://kyluc.vn/Userfiles/Upload/images/3541971902_b7cf5346e4_c.jpg', 'https://kyluc.vn/Userfiles/Upload/images/Stationspleinstalling_fietsenrekken_(1).jpg'] },
  ];

  return (
    <div className="parking-map">
      {sections.map((section, index) => (
        <div key={index} className="section">
          <h2>{section.title}</h2>
          <div className="main-image">
            <img
              src={section.images[0]}
              alt={`${section.title} main`}
              onClick={() => handleImageClick(section.images[0])}
            />
          </div>
          <div className="thumbnail-row">
            {section.images.map((image, idx) => (
              <img
                key={idx}
                src={image}
                alt={`${section.title} thumbnail ${idx + 1}`}
                className="thumbnail"
                onClick={() => handleImageClick(image)}
              />
            ))}
          </div>
        </div>
      ))}
      {selectedImage && (
        <div className="image-viewer" onClick={closeImageViewer}>
          <img src={selectedImage} alt="Selected" />
          <span className="close-button" onClick={closeImageViewer}>
            ×
          </span>
        </div>
      )}
    </div>
  );
}

export default Parkingmap;
// import a1 from './images/a1.jpg';
// import a2 from './images/a2.jpg';
// import a3 from './images/a3.jpg';
// import b1 from './images/b1.jpg';
// import b2 from './images/b2.jpg';
// import b3 from './images/b3.jpg';
// import c1 from './images/c1.jpg';
// import c2 from './images/c2.jpg';
// import c3 from './images/c3.jpg';

// const sections = [
//   { title: 'Khu A', images: [a1, a2, a3] },
//   { title: 'Khu B', images: [b1, b2, b3] },
//   { title: 'Khu C', images: [c1, c2, c3] },
// ];
